import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { EnfisQuasar } from '@enfis/quasar';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import i18n from './i18n';
import 'animate.css';

createApp(App)
    .use(i18n)
    .use(store)
    .use(Quasar, quasarUserOptions)
    .use(router)
    .use(EnfisQuasar, {
        config: {
            io: { baseURL: process.env.VUE_APP_ENFIS_BASE_URL },
        },
    })
    .mount('#app');
