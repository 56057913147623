import './styles/quasar.scss';
import lang from 'quasar/lang/sk.js';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import Dialog from 'quasar/src/plugins/Dialog.js';import Meta from 'quasar/src/plugins/Meta.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';;

// To be used on app.use(Quasar, { ... })
export default {
    plugins: { Dialog, Notify, Loading },
    config: {},
    plugins: [Dialog, Meta, Notify, Loading],
    lang: lang,
};
