<template>
    <router-view />
</template>

<script>
import { LocalStorage } from 'quasar';

export default {
    created() {
        const enter = () => {
            this.$router.enter();
        };

        const activeLanguage = LocalStorage.getItem('akcabak.sk');
        this.$i18n.locale = activeLanguage || 'sk';

        this.$e.io.callMethod('getSettingList', null, {
            success: (data) => {
                let contactInfo = {
                    nazovDomeny: 'www.' + data[8].value,
                    nazovProjektu: data[9].value,
                    hlavnySlogan: data[12].value,
                    kratkyPopis: data[13].value,
                    kontaktnyMail: data[15].value,
                    kontaktnyTelefon: data[16].value,
                    googleMapsUrl: data[17].value,
                    sidlo: data[18].value,
                    fakturacnaAdresa: data[19].value,
                    metaPopis: data[22].value,
                };
                this.$store.commit('setContactInfo', contactInfo);
                enter();
            },
            error: (error) => {
                return false;
            },
        });
    },
    name: 'App',
    data() {
        return {};
    },
};
</script>
