import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import MainLayout from '@/layouts/MainLayout.vue';
import store from './store';

const routes = [
    {
        name: 'root',
        path: '/:pathMatch(.*)*',
        beforeEnter(to, from) {
            return false;
        },
    },
];

const mainRoutes = [
    {
        path: '/',
        component: MainLayout,
        redirect: { name: 'home' },
        children: [
            {
                path: '/:link?',
                name: 'home',
                component: () => import(/* webpackChunkName: "" */ '@/views/home/homeView.vue'),
            },
            {
                path: '/pravne_specializacie',
                name: 'pravne_specializacie',
                component: () => import(/* webpackChunkName: "pravne_specializacie" */ '@/views/pravneSpecializacie/pravneSpecializacieView.vue'),
            },
            {
                path: '/obcianskepravo',
                name: 'obcianskepravo',
                component: () => import(/* webpackChunkName: "obcianskepravo" */ '@/views/obcianskepravoView.vue'),
            },
        ],
    },
];

const router = createRouter({
    history: process.env.VUE_APP_ROUTER_MODE == 'hash' ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
    routes,
});

router.addRoutes = function (routes) {
    router.options.routes = routes;
    routes.forEach((route) => {
        router.addRoute(route);
    });
};
router.removeRoutes = function () {
    router.options.routes.forEach((route) => {
        router.removeRoute(route.name);
    });
    router.options.routes = [];
};
router.reload = function () {
    const path = window.location.href.replace(window.location.origin + router.options.history.base, '');
    router.replace(router.resolve(path));
};
router.enter = function () {
    router.removeRoutes();
    router.addRoutes(mainRoutes);
    router.reload();
};

export default router;
