<template>
    <q-layout view="lHh lpr lFf">
        <app-navigation></app-navigation>
        <q-page-container>
            <router-view v-slot="{ Component, route }">
                <transition
                    name="route"
                    mode="out-in"
                >
                    <q-page
                        :key="route.name"
                        :style-fn="$e.utils.pageStyleFn"
                    >
                        <component :is="Component" />
                    </q-page>
                </transition>
            </router-view>
        </q-page-container>
    </q-layout>
</template>

<script>
import AppNavigation from '@/components/navigation.vue';
import { createMetaMixin } from 'quasar';
import $e from '@enfis/quasar';

export default {
    components: { AppNavigation },
    mixins: [
        createMetaMixin(function () {
            let metaTitle = this.$store.state.title;
            let metaDescription = this.$store.state.description;
            let metaImage = this.$store.state.image;
            let metaDataObj = {
                title: metaTitle,
                meta: {
                    title: { name: 'title', content: metaTitle },
                    description: { name: 'description', content: metaDescription },

                    //      Open Graph / Facebook     //
                    ogType: {
                        property: 'og:type',
                        content: 'website',
                    },
                    ogUrl: {
                        property: 'og:url',
                        content: window.location.href,
                    },
                    ogTitle: {
                        property: 'og:title',
                        content: metaTitle,
                    },
                    ogDescription: {
                        property: 'og:description',
                        content: metaDescription,
                    },
                    ogImage: {
                        property: 'og:image',
                        content: metaImage,
                    },

                    //      Twitter     //
                    twitterCard: {
                        property: 'twitter:card',
                        content: 'summary_large_image',
                    },
                    twitterUrl: {
                        property: 'twitter:url',
                        content: window.location.href,
                    },
                    twitterTitle: {
                        property: 'twitter:title',
                        content: metaTitle,
                    },
                    twitterDescription: {
                        property: 'twitter:description',
                        content: metaDescription,
                    },
                    twitterImage: {
                        property: 'twitter:image',
                        content: metaImage,
                    },
                },
            };
            return metaDataObj;
        }),
    ],
    data() {
        return {};
    },
};
</script>

<style lang="scss">
// route transition
.mainContent {
    min-height: 100vh;
}
.route-enter-from {
    opacity: 0;
    transform: scale(0.99);
}

.route-enter-active,
.route-leave-active {
    transition: all 300ms ease;
}

.route-leave-to {
    opacity: 0;
}

:root {
    --dl-color-gray-500: #595959;
    --dl-color-gray-700: #999999;
    --dl-color-gray-900: #d9d9d9;
    --dl-size-size-large: 144px;
    --dl-size-size-small: 48px;
    --dl-color-gray-black: #000000;
    --dl-color-gray-white: #ffffff;
    --dl-size-size-medium: 96px;
    --dl-size-size-xlarge: 192px;
    --dl-size-size-xsmall: 16px;
    --dl-space-space-unit: 16px;
    --dl-size-size-xxlarge: 288px;
    --dl-size-size-maxwidth: 1400px;
    --dl-radius-radius-round: 50%;
    --dl-space-space-halfunit: 8px;
    --dl-space-space-sixunits: 96px;
    --dl-space-space-twounits: 32px;
    --dl-radius-radius-radius2: 2px;
    --dl-radius-radius-radius4: 4px;
    --dl-radius-radius-radius8: 8px;
    --dl-space-space-fiveunits: 80px;
    --dl-space-space-fourunits: 64px;
    --dl-space-space-threeunits: 48px;
    --dl-space-space-oneandhalfunits: 24px;
}

.input {
    color: var(--dl-color-gray-black);
    cursor: auto;
    padding: 0.5rem 1rem;
    border-color: rgba(11, 64, 113, 0.35);
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
}
.textarea {
    color: var(--dl-color-gray-black);
    width: 100%;
    cursor: auto;
    padding: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-halfunit);
    border-color: rgba(11, 64, 113, 0.35);
    border-radius: 4px;
    background-color: var(--dl-color-gray-white);
}

.list {
    width: 100%;
    margin: 1em 0px 1em 0px;
    display: block;
    padding: 0px 0px 0px 1.5rem;
    list-style-type: none;
    list-style-position: outside;
}

.teleport-show {
    display: flex !important;
    transform: translateY(0%) !important;
}

.offer {
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
}
.features {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    max-width: 1200px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
}

.contact {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.nav {
    width: 100%;
    display: flex;
    max-width: 1200px;
    margin-left: auto;
    padding-top: var(--dl-space-space-twounits);
    margin-right: auto;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
}
.container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: flex;
    padding: 32 40;
    flex-flow: wrap;
    max-width: 1200px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
}

.form-label {
    color: #ffffff;
    font-size: calc(1rem + 0.3vw) !important;
    margin-top: var(--dl-space-space-twounits);
}
.flex-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding: 32 40;
    flex-wrap: wrap;
    max-width: 1200px;
    align-items: center;
    justify-content: center;
}
.flex-item {
    border: 1px solid red;
    padding: var(--dl-space-space-twounits);
    flex-basis: 25%;
    text-align: center;
    border-radius: 20px;
}

.box {
    flex: 1;
    margin: 20px;
    padding: var(--dl-space-space-twounits);
    font-size: 20px !important;
    align-self: stretch;
    box-sizing: border-box;
    flex-basis: 20%;
    text-align: center;
}
.offer-box {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-flow: wrap;
    max-width: 1200px;
    flex-direction: row;
}
.off {
    padding: var(--dl-space-space-twounits);
    flex-basis: 20%;
    text-align: center;
    background-color: rgba(0, 20, 40, 0.6);
}

.contact_div {
    flex: 1 1 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.form-label1 {
    color: #ffffff;
    font-size: calc(1rem + 0.3vw) !important;
    margin-top: var(--dl-space-space-twounits);
}
.offer-box1 {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: flex;
    padding: 32px 48px;
    flex-flow: wrap;
    max-width: 1200px;
}
.off1 {
    padding: var(--dl-space-space-twounits);
    flex-basis: 24%;
    text-align: center;
    background-color: rgba(0, 20, 40, 0.6);
}
.offer-box2 {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: flex;
    padding: 32px 48px;
    flex-flow: wrap;
    max-width: 1200px;
}
.off2 {
    padding: var(--dl-space-space-fourunits);
    flex-basis: 24%;
    text-align: center;
    background-color: rgba(0, 20, 40, 0.6);
}
.mobile-nav-a {
    flex: 0 0 auto;
    width: 100%;
    height: 4rem;
    display: flex;
    padding: 0 2rem;
    align-items: flex-start;
    border-bottom: 0.0625rem solid #e2e4ed;
    flex-direction: column;
    justify-content: center;
}
.Heading {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
}
.Content {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
}
@media (max-width: 992px) {
    .box {
        flex-basis: 40%;
    }
    .off {
        flex-basis: 40%;
    }
    .off1 {
        flex-basis: 40%;
    }
    .off2 {
        flex-basis: 40%;
    }
    .service {
        flex-flow: wrap;
    }

    .reklam {
        padding: 100px 15px;
    }
}
@media (max-width: 991px) {
    .container {
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 768px) {
    .features {
        padding: 0px;
        align-items: center;
        justify-content: flex-start;
    }
    .container {
        align-self: center;
        padding-top: var(--dl-space-space-twounits);
        padding-left: var(--dl-space-space-threeunits);
        padding-right: var(--dl-space-space-threeunits);
        padding-bottom: var(--dl-space-space-twounits);
        justify-content: center;
    }
    .box {
        flex-basis: 90%;
    }
    .off {
        flex-basis: 90%;
    }
    .sluzby {
        display: block;
    }
    .sluzba {
        flex-basis: 90%;
    }
    .contact_div {
        margin-top: var(--dl-space-space-fiveunits);
    }
    .off1 {
        flex-basis: 90%;
    }
    .off2 {
        flex-basis: 90%;
    }
}
@media (max-width: 480px) {
    .features {
        height: auto;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }
    .container {
        align-items: stretch;
        padding-top: var(--dl-space-space-twounits);
        padding-left: var(--dl-space-space-threeunits);
        padding-right: var(--dl-space-space-threeunits);
        flex-direction: row;
        padding-bottom: var(--dl-space-space-twounits);
        justify-content: center;
    }
}
@media (max-width: 479px) {
    .nav {
        padding: var(--dl-space-space-unit);
    }
    .container {
        grid-gap: var(--dl-space-space-halfunit);
    }
}
@media (max-width: 320px) {
    .features {
        height: auto;
        padding-top: 0px;
        padding-left: 0px;
        margin-bottom: auto;
        padding-right: 0px;
        padding-bottom: 0px;
    }
}
</style>
