export default {
  "enfis": {
    "modules": {
      "user": {
        "permissionDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No permissions!"])}
      }
    }
  },
  "common": {
    "btn": {
      "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
    },
    "required_filed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
    "longer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter longer text"])},
    "bad_mail_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect format"])}
  },
  "menu": {
    "domov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "pravne_specializacie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertise"])},
    "o_nas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "home": {
    "1view": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FOCUSED ON SUCCESS"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprehensive legal services"])}
    },
    "2view": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Expertise"])},
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civil law"])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial law"])},
      "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelectual property law"])},
      "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labour law"])}
    },
    "3view": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use our"])},
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drive"])},
      "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creativity"])},
      "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambition"])}
    },
    "4view": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our law firm with more than 14 years of experience provides comprehensive legal advice, including representation before courts and state authorities. We provide legal services to our clients efficiently and at the highest professional level."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are aware of the client's needs as well as the fact that each case is individual and requires a detailed analysis with the aim of knowing all the possibilities and risks."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In cooperation with auditors, tax and economic advisors, bankruptcy trustees and experts, we always bring you the choice of the right solution with regard to your commercial needs."])},
        (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Our clients are natural persons and domestic and foreign legal entities and investors, to whom we provide legal advice right from the establishment of a Slovak entity or the purchase of an existing company. Among other issues, we ensure obtaining all necessary permits for the purpose of carrying out business activities, drafting internal labor law regulations, solving various regulatory issues, as well as covering all business contractual relationships of clients. You can find a more detailed description of our services in the Our expertise section ", _interpolate(_named("routerLink")), "."])}
      ]
    },
    "5view": {
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reliable partner"])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on the way to achieve your goal"])}
    }
  },
  "contact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not hesitate to contact us directly or via following form"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AK Peter Čabák, s.r.o.<br/>Registered in Business Register of the City Court Košice, section: Sro, Insert No.: 43111/V.<br/>Južná trieda 2/A<br/>040 01 Košice<br/><br/>ID: 51 336 448<br/>VAT ID: SK2120671366"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved. Created by"])},
    "msgSendCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message was succesfully sent"])},
    "msgSendError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error somewhere. Please try again later..."])}
  },
  "pravneSpecializacie": {
    "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civil law"])},
    "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial law"])},
    "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intelectual property law"])},
    "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labour law"])},
    "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mergers and acquisitions"])},
    "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection of personality rights"])},
    "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family law"])},
    "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispute agenda"])},
    "item9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR"])},
    "item10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register of public sector partners"])},
    "item11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankruptcy and restructuring"])},
    "item12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public procurement"])},
    "mt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your interest in our legal services. We are intensively working on their description. Do not hesitate to contact us directly."])}
  }
}