export default {
  "enfis": {
    "modules": {
      "user": {
        "permissionDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadne, alebo nedostatočné prístupové práva!"])}
      }
    }
  },
  "common": {
    "btn": {
      "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť viac"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať"])}
    },
    "required_filed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povinné pole"])},
    "longer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte dlhší text"])},
    "bad_mail_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávny format"])}
  },
  "menu": {
    "domov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domov"])},
    "pravne_specializacie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právne špecializácie"])},
    "o_nas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nás"])},
    "kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
  },
  "home": {
    "1view": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZAMERANÝ NA ÚSPECH"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplexné právne poradenstvo"])}
    },
    "2view": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právne špecializácie"])},
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Občianske právo"])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchodné právo"])},
      "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právo duševného vlastníctva"])},
      "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovné právo"])}
    },
    "3view": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponúkame Vám"])},
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skúsenosti"])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zanietenie"])},
      "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreativitu"])},
      "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ctižiadostivosť"])}
    },
    "4view": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nás"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sme advokátska kancelária s viac ako 14 ročnými skúsenosťami poskytujúca komplexné právne poradenstvo vrátane zastupovania pred súdmi a štátnymi orgánmi. Svojím klientom poskytujeme právny servis efektívne a na najvyššej profesionálnej úrovni."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uvedomujeme si potreby klienta ako aj skutočnosť, že každý prípad je individuálny a vyžaduje si detailnú analýzu s cieľom poznania všetkých možností i rizík."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V spolupráci s audítormi, daňovými a ekonomickými poradcami, správcami a znalcami Vám prinášame vždy výber správneho riešenia s ohľadom na Vaše komerčné potreby."])},
        (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Našimi klientmi sú fyzické osoby, domáce a zahraničné právnické osoby i investori, ktorým poskytujeme právne poradenstvo už od založenia slovenskej entity alebo odkúpenia existujúcej spoločnosti. Okrem iného zabezpečujeme získanie všetkých potrebných povolení za účelom vykonávania podnikateľskej činnosti, vypracovanie interných pracovnoprávnych predpisov, riešenie rôznych regulatórnych otázok, ako i zastrešenie všetkých obchodných zmluvných vzťahov klientov. Bližší popis našich služieb nájdete v sekcii ", _interpolate(_named("routerLink")), "."])}
      ]
    },
    "5view": {
      "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoľahlivý partner"])},
      "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na ceste za Vašim cieľom"])}
    }
  },
  "contact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neváhajte nás kontaktovať priamo alebo prostredníctvom formulára"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefónne číslo"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AK Peter Čabák, s.r.o.<br/>Zapísaná v Obchodnom registri Mestského súdu Košice, oddiel Sro, vložka číslo 43111/V.<br/>Južná trieda 2/A<br/>040 01 Košice<br/><br/>IČO: 51 336 448<br/>IČ DPH: SK2120671366"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všetky práva vyhradené. Vytvoril"])},
    "msgSendCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa bola úspešne odoslaná"])},
    "msgSendError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niekde nastala chyba. Skúste to prosím neskôr..."])}
  },
  "pravneSpecializacie": {
    "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Občianske právo"])},
    "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchodné právo"])},
    "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právo duševného vlastníctva"])},
    "item4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovné právo"])},
    "item5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fúzie a akvizície"])},
    "item6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochrana osobnosti"])},
    "item7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodinné právo"])},
    "item8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporová agenda"])},
    "item9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR"])},
    "item10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register partnerov verejného sektora"])},
    "item11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konkurz a reštrukturalizácia"])},
    "item12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verejné obstarávanie"])},
    "mt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďakujeme Vám za Váš záujem o naše právne služby. Na ich popise intenzívne pracujeme. Neváhajte sa však na nás priamo obrátiť."])}
  }
}