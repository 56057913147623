<template>
    <div class="navigation-navigation">
        <header
            data-thq="thq-navbar"
            class="navigation-navbar-interactive"
        >
            <router-link
                :to="{ name: 'home' }"
                class="navigation-navlink"
                @click="toTop"
            >
                <img alt="cabak_logo" src=@/assets/lg.jpg loading="lazy" class="navigation-logo" />
            </router-link>
            <div
                data-thq="thq-navbar-nav"
                data-role="Nav"
                class="navigation-desktop-menu gt-sm"
            >
                <nav
                    data-thq="thq-navbar-nav-links"
                    data-role="Nav"
                    class="navigation-nav q-col-gutter-x-lg"
                >
                    <router-link
                        :to="{ name: 'home' }"
                        class="navigation-navlink"
                        @click="toTop"
                    >
                        <p class="navigation-text">{{ $t('menu.domov') }}</p>
                    </router-link>
                    <router-link
                        to="/pravne_specializacie"
                        class="navigation-navlink"
                    >
                        <p class="navigation-text">{{ $t('menu.pravne_specializacie') }}</p>
                    </router-link>
                    <span
                        @click="moveToOnas"
                        class="navigation-navlink cursor-pointer"
                    >
                        <p class="navigation-text">{{ $t('menu.o_nas') }}</p>
                    </span>
                    <span
                        @click="moveToContact"
                        class="navigation-navlink cursor-pointer"
                    >
                        <p class="navigation-text">{{ $t('menu.kontakt') }}</p>
                    </span>
                    <q-select
                        bg-color="primary"
                        color="primary"
                        outlined
                        v-model="selectedLanguage"
                        :options="languageList"
                        class="q-ml-xl langSelect"
                        popup-content-style="text-transform: uppercase;"
                        @update:model-value="selectLanguage"
                    />
                </nav>
            </div>
            <div
                data-thq="thq-burger-menu"
                class="navigation-burger-menu lt-md"
                @click="toggleMenu"
            >
                <svg
                    viewBox="0 0 1024 1024"
                    class="navigation-icon2"
                >
                    <path
                        d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
                    ></path>
                </svg>
                <p class="navigation-text08">Menu</p>
            </div>
            <div
                data-thq="thq-mobile-menu"
                class="scroll"
                :class="this.openedMenu ? 'navigation-mobile-menu navigation-mobile-menu-opened' : 'navigation-mobile-menu'"
            >
                <div
                    data-thq="thq-mobile-menu-nav"
                    data-role="Nav"
                    class="navigation-nav1"
                >
                    <div class="navigation-container">
                        <router-link
                            :to="{ name: 'home' }"
                            class="navigation-navlink"
                            @click="toTop"
                        >
                            <img alt="cabak_logo" src=@/assets/logo.svg loading="lazy" class="navigation-logo" />
                        </router-link>
                        <div
                            data-thq="thq-close-menu"
                            class="navigation-menu-close"
                            @click="toggleMenu"
                        >
                            <svg
                                viewBox="0 0 1024 1024"
                                class="navigation-icon4"
                            >
                                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                            </svg>
                            <span class="navigation-text09">Menu</span>
                        </div>
                    </div>
                    <nav
                        data-thq="thq-mobile-menu-nav-links"
                        data-role="Nav"
                        class="navigation-nav2"
                    >
                        <router-link
                            :to="{ name: 'home' }"
                            class="navigation-link"
                            @click="toTop"
                        >
                            <div class="navigation-container1 mobile-nav-a">{{ $t('menu.domov') }}</div>
                        </router-link>

                        <router-link
                            to="/pravne_specializacie"
                            class="navigation-link"
                            @click="toggleMenu"
                        >
                            <div class="navigation-container1 mobile-nav-a">{{ $t('menu.pravne_specializacie') }}</div>
                        </router-link>
                        <span
                            @click="moveToOnas"
                            class="navigation-navlink cursor-pointer full-width"
                        >
                            <p class="navigation-container1 mobile-nav-a">{{ $t('menu.o_nas') }}</p>
                        </span>
                        <span
                            @click="moveToContact"
                            class="navigation-navlink cursor-pointer full-width"
                        >
                            <p class="navigation-container1 mobile-nav-a">{{ $t('menu.kontakt') }}</p>
                        </span>
                        <q-select
                            bg-color="primary"
                            color="primary"
                            outlined
                            v-model="selectedLanguage"
                            :options="languageList"
                            class="q-mt-xl langSelect"
                            style="margin-left: 32px"
                            popup-content-style="text-transform: uppercase;"
                            @update:model-value="selectLanguage"
                        />
                    </nav>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
import { scroll } from 'quasar';
import { LocalStorage } from 'quasar';
const { getScrollTarget, setVerticalScrollPosition } = scroll;

export default {
    created() {
        this.selectedLanguage = this.$i18n.locale;
    },

    name: 'Navigation',
    props: {
        rootClassName: String,
    },
    data() {
        return {
            openedMenu: false,
            languageList: this.$i18n.availableLocales.reverse(),
            selectedLanguage: '',
        };
    },
    methods: {
        toggleMenu: function () {
            this.openedMenu = !this.openedMenu;
        },
        toTop: function () {
            window.scrollTo(0, 0);
            this.openedMenu = false;
        },
        moveToContact: function () {
            this.openedMenu = false;
            const el = document.getElementById('contact');
            const target = getScrollTarget(el);
            const offset = el.offsetTop - 50;
            setVerticalScrollPosition(target, offset);
        },
        moveToOnas: function () {
            this.openedMenu = false;
            let currRoute = this.$route.name;
            if (currRoute == 'home') {
                this.$router.replace({ name: 'home', params: { link: 'onas' } });
                const el = document.getElementById('about_us');
                const target = getScrollTarget(el);
                const offset = el.offsetTop - 50;
                setVerticalScrollPosition(target, offset);
            } else {
                this.$router.push({ name: 'home', params: { link: 'onas' } });
            }
        },
        selectLanguage(val) {
            this.$i18n.locale = val;
            LocalStorage.set('akcabak.sk', val);
        },
    },
};
</script>

<style lang="scss" scoped>
.navigation-navigation {
    top: 0;
    flex: 0 0 auto;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    z-index: 1;
    position: fixed;
    box-shadow: 5px 0px 30px 0px rgba(0, 0, 0, 0.28);
    align-items: flex-start;
    flex-direction: row;
    background-color: var(--dl-color-gray-white);
    min-width: 360px;
}
.navigation-navbar-interactive {
    width: 100%;
    display: flex;
    max-width: 1200px;
    align-items: center;
    margin-left: auto;
    padding: 16px 30px;
    margin-right: auto;
    justify-content: space-between;
}
.navigation-logo {
    height: 80px;
    text-decoration: none;
}
.navigation-desktop-menu {
    display: flex;
}
.navigation-nav {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.navigation-link {
    width: 100%;
}
.navigation-text {
    font-weight: 600;
    &:hover {
        color: $primary;
    }
}
.navigation-text01 {
    margin-left: var(--dl-space-space-twounits);
}
.navigation-text01:hover {
    color: $primary;
    font-weight: 600;
}
.navigation-link1 {
    display: contents;
}
.navigation-text02 {
    margin-left: var(--dl-space-space-twounits);
}
.navigation-text02:hover {
    color: $primary;
    font-weight: 600;
}
.navigation-link2 {
    display: contents;
}
.navigation-text03 {
    margin-left: var(--dl-space-space-twounits);
    text-decoration: none;
}
.navigation-text03:hover {
    color: $primary;
    font-weight: 600;
}
.navigation-text04 {
    font-size: 14px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    margin-right: 4px;
    vertical-align: middle;
}
.navigation-dropdown-arrow {
    align-self: center;
    transition: 0.3s;
}
.navigation-icon {
    fill: var(--dl-color-gray-white);
    height: 10px;
}

.navigation-text05 {
    width: 100%;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}
.navigation-dropdown1 {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: var(--dl-radius-radius-radius2);
}
.navigation-text06 {
    width: 100%;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}
.navigation-dropdown2 {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: var(--dl-radius-radius-radius2);
}
.navigation-text07 {
    width: 100%;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}
.navigation-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
}
.navigation-icon2 {
    fill: #135b9e;
    cursor: pointer;
    height: 30px;
}
.navigation-text08 {
    color: #135b9e;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 0.2rem;
    text-transform: uppercase;
}
.navigation-mobile-menu {
    top: 0px;
    right: -150vw;
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 2rem 3rem;
    z-index: 100;
    position: fixed;
    /* transform: translateX(-100vw); */
    transition: right 0.5s;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
}

.navigation-mobile-menu-opened {
    right: 0;

    @media (min-width: 1024px) {
        right: -150vw;
    }
}

.navigation-nav1 {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.navigation-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navigation-menu-close {
    display: flex;
    align-items: center;
    justify-content: center;
}
.navigation-icon4 {
    fill: #135b9e;
    cursor: pointer;
    height: 35px;
}
.navigation-text09 {
    color: #135b9e;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
}
.navigation-nav2 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.navigation-container1 {
    flex: 0 0 auto;
    height: 4rem;
    display: flex;
    padding: 0 2rem;
    font-weight: 600;
}
.navigation-link3 {
    text-decoration: none;
}
.navigation-container2 {
    flex: 0 0 auto;
    height: 4rem;
    display: flex;
    padding: 0 2rem;
}
.navigation-navlink2 {
    text-decoration: none;
}
.navigation-container3 {
    flex: 0 0 auto;
    height: 4rem;
    display: flex;
    padding: 0 2rem;
}
.navigation-link4 {
    text-decoration: none;
}
.navigation-container4 {
    flex: 0 0 auto;
    height: 4rem;
    display: flex;
    padding: 0 2rem;
}
.navigation-link5 {
    text-decoration: none;
}
.navigation-languagevalidator1 {
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin-top: 1rem;
    margin-left: var(--dl-space-space-twounits);
    border-radius: var(--dl-radius-radius-radius2);
}
.navigation-dropdown-toggle4 {
    color: #ffffff;
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding-top: 10px;
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 10px;
    background-color: #135b9e;
}
.navigation-text10 {
    font-size: 14px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    margin-right: 4px;
    vertical-align: middle;
}
.navigation-dropdown-arrow1 {
    align-self: center;
    transition: 0.3s;
}
.navigation-icon6 {
    fill: var(--dl-color-gray-white);
    height: 10px;
}
.navigation-dropdown-list1 {
    left: 0%;
    width: max-content;
    display: none;
    z-index: 100;
    position: absolute;
    min-width: 100%;
    transition: 0.3s;
    align-items: stretch;
    border-color: #d9d9d9;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    list-style-type: none;
    background-color: var(--dl-color-gray-white);
    list-style-position: inside;
}
.navigation-dropdown3 {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: var(--dl-radius-radius-radius2);
}
.navigation-dropdown-toggle5 {
    fill: #595959;
    color: #595959;
    width: 100%;
    display: inline-flex;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
}
.navigation-dropdown-toggle5:hover {
    fill: #fff;
    color: #fff;
    background-color: #595959;
}
.navigation-text11 {
    width: 100%;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}
.navigation-dropdown4 {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: var(--dl-radius-radius-radius2);
}
.navigation-dropdown-toggle6 {
    fill: #595959;
    color: #595959;
    width: 100%;
    display: inline-flex;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
}
.navigation-dropdown-toggle6:hover {
    fill: #fff;
    color: #fff;
    background-color: #595959;
}
.navigation-text12 {
    width: 100%;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}
.navigation-dropdown5 {
    cursor: pointer;
    display: inline-block;
    position: relative;
    border-radius: var(--dl-radius-radius-radius2);
}
.navigation-dropdown-toggle7 {
    fill: #595959;
    color: #595959;
    width: 100%;
    display: inline-flex;
    transition: 0.3s;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-halfunit);
}
.navigation-dropdown-toggle7:hover {
    fill: #fff;
    color: #fff;
    background-color: #595959;
}
.navigation-text13 {
    width: 100%;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.langSelect {
    :deep() .q-field__inner {
        .q-field__native,
        .q-icon {
            color: white;
        }

        .q-field__native span {
            text-transform: uppercase;
        }
    }
}

@media (max-width: $breakpoint-sm-max) {
    .navigation-logo {
        height: 60px;
    }
    .navigation-navbar-interactive {
        padding: 8px 15px;
    }
    .navigation-mobile-menu {
        padding: 7px 15px;
    }
}
</style>
