import { createStore } from 'vuex';
import createMetaMixin from 'quasar/src/utils/create-meta-mixin.js';;

export default createStore({
    state: {
        title: null,
        description: null,
        image: null,
        contactInfo: null,
    },
    getters: {},
    mutations: {
        setMetaTitle(state, title) {
            state.title = title;
        },
        setMetaDescription(state, description) {
            state.description = description;
        },
        setMetaImage(state, image) {
            state.image = image;
        },
        setContactInfo(state, contactInfo) {
            state.contactInfo = contactInfo;
        },
    },
    actions: {},
    modules: {},
});
